footer {
  background: url(../img/footer.png) 50%/cover no-repeat;
  position: relative;
  padding: 1rem 0;
  text-align: center;
  // font-size: .85rem;
  color: #fff;

  a {
    color: #fff;
  }

  a:hover {
    color: #fff;
  }

  span {
    color: #fff;
  }
  
  h2 {
    color: $primary;
  }

  section {
    padding: 4rem 0 !important;
  }
}

.fa-stack {
  transition: 0.33s;
  color: #fff;
}

footer::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #333;
  opacity: .70;
  content: "";
}
