.jumbotron {
  margin-bottom: 0;
}

body {
  background: #333;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.section {
  padding: 5rem 0;
}

.btn {
  border-radius: 0px;
  background: #333;
  padding: 15px;
}

h1 {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  font-weight: bold;
}

h2 {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  font-weight: bold;
}

h5 {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  font-weight: bold;
}

i.fa-circle {
  color: #fff;
}
