header {

  .logo {
    padding: 20px;
    display: block;

    img {
      width: 250px;
      height: auto;
      position: absolute;
      text-align: center;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

.jumbotron {
  background: url(../img/hero.png) 50%/cover no-repeat;
  position: relative;
  color: #fff;
  border-radius: 0;
  padding: 6.5rem 0 !important;
  padding-top: 17rem !important;

  .fa-stack {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
    transition: 0.33s;
    color: #fff;
  }
  
  .fa-stack:hover {
    opacity: 1;
  }
}

.jumbotron::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #333;
  opacity: .70;
  content: "";
}

.cta-bar {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #C3C3C3;
  color: #333;

  a {
    color: #333;
    text-decoration: underline;
  }
}